










































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import AddHouseholdMemberActionForm from "@/components/forms/AddHouseholdMemberActionForm.vue";
import HouseholdMemberActionSessionsTable from "@/components/HouseholdMemberActionSessionsTable.vue";
import AddHouseholdMemberSessionForm from "@/components/forms/AddHouseholdMemberSessionForm.vue";
import DynamicForm from "@/components/DynamicForm.vue";
import Loader from "@/components/Loader.vue";
@Component({
  components: {
    ModelTable,
    AddHouseholdMemberActionForm,
    HouseholdMemberActionSessionsTable,
    AddHouseholdMemberSessionForm,
    DynamicForm,
    Loader
  }
})
export default class HouseholdMemberNote extends Vue {
  @Prop()
  public id!: string;
  item: any = null;
  note: any = null;
  notes: any[] = [];
  intakeFormSubmissions: any[] | null = null;
  referrals: any[] = [];
  member: any = null;
  async mounted() {
    this.member = await this.$service.providers.householdMembers.fetchItemAsync(
      this.id
    );
  }

  async initialize() {
    const notes = await this.$service.getMemberNotes(this.id);
    console.log(notes);
    this.item = notes.data;
    console.log(this.item);
  }
}
